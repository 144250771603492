/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// GENERATE THEME FROM HEX HERE: http://mcg.mbitson.com/
$primary: (
  50: #e4edf0,
  100: #bad2d9,
  200: #8db4bf,
  300: #5f96a5,
  400: #3c8092,
  500: #1a697f,
  600: #176177,
  700: #13566c,
  800: #0f4c62,
  900: #083b4f,
  A100: #84d7ff,
  A200: #51c6ff,
  A400: #1eb5ff,
  A700: #04adff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$accent: (
  50: #fff3eb,
  100: #fee2ce,
  200: #fecfad,
  300: #fdbc8c,
  400: #fcad74,
  500: #fc9f5b,
  600: #fc9753,
  700: #fb8d49,
  800: #fb8340,
  900: #fa722f,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffe2d5,
  A700: #ffd1bc,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$primary: mat-palette($primary);
$accent: mat-palette($accent, 500, 400, 700);
// The warn palette is optional (defaults to red).
$warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat-light-theme($primary, $accent, $warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($theme);